import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import NavItems from "./NavItems";

export default function MobileNav() {
  const [isOpen, setOpen] = useState(false);

  const hamburgerIcon = (
    <GiHamburgerMenu
      className="Icon"
      color="var(--primary)"
      size="2rem"
      onClick={() => setOpen(true)}
    />
  );

  const closeIcon = (
    <MdClose
      className="Icon"
      color="var(--primary)"
      size="2rem"
      onClick={() => setOpen(false)}
    />
  );

  return (
    <div className="mobile-nav">
      <>{isOpen ? closeIcon : hamburgerIcon}</>
      {isOpen && <NavItems />}
    </div>
  );
}
