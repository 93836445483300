import "./404.css";

import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <div className="page-404">
      <div className="inner-container">
        <h1>Sorry...</h1>
        <p>We couldn't find that page</p>
        <Link className="link" to="/">
          Return to homepage
        </Link>
      </div>
    </div>
  );
}
