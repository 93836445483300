import "./Home.css";

import pfp from "./images/pfp.png";
import java_icon from "./images/java-icon.png";
import kotlin_icon from "./images/kotlin-icon.png";
import python_icon from "./images/python-icon.webp";
import ts_icon from "./images/ts-icon.png";
import react_icon from "./images/react-icon.png";
import badger from "./images/badgervolunteering.png";
import crypto from "./images/cryptotrackr.png";
import fear_of_dark from "./images/fear-of-dark.png";
import maze_gen from "./images/maze-gen.png";
import { ReactComponent as Cap } from "./images/graduation-cap.svg";
import { ReactComponent as Suitcase } from "./images/suitcase.svg";
import {
  BsGithub,
  BsInstagram,
  BsLinkedin,
  BsEnvelopeFill,
} from "react-icons/bs";

interface PCard {
  name: string;
  desc: string;
  img: string;
  demo?: string;
  source?: string;
}

const ProjectCard = (proj: PCard) => {
  return (
    <div className="project-card">
      <div className="project-info">
        <h3>{proj.name}</h3>
        <p>{proj.desc}</p>
        {(proj.demo || proj.source) && (
          <div className="buttons">
            {proj.demo && (
              <a className="demo" href={proj.demo}>
                Demo
              </a>
            )}
            {proj.source && (
              <a className="source" href={proj.source}>
                Source
              </a>
            )}
          </div>
        )}
      </div>
      <img src={proj.img} alt="" />
    </div>
  );
};

export default function Home() {
  return (
    <div className="home">
      <section className="intro">
        <ul className="intro-card">
          <li>
            <code className="intro-msg">Hello, I'm Wes.</code>
          </li>
          <li>
            <div className="circle">
              <img className="pfp" src={pfp} alt=""></img>
            </div>
          </li>
        </ul>
      </section>
      <section id="about" className="about">
        <h2>About</h2>
        <ul>
          <li>
            <Cap className="icon" />
            <h3>Education</h3>
            <p>
              <strong>Cal Poly: SLO</strong> - Computer Science
            </p>
          </li>
          <li>
            <Suitcase className="icon" />
            <h3>Current Employment</h3>
            <p>
              <strong>Intuit</strong> - Software Engineer 1
            </p>
          </li>
        </ul>
      </section>
      <section id="technologies" className="technologies">
        <h2>Technologies</h2>
        <ul>
          <li>
            <img src={ts_icon} alt="typescript"></img>
          </li>
          <li>
            <img src={kotlin_icon} alt="kotlin"></img>
          </li>
          <li>
            <img src={python_icon} alt="python"></img>
          </li>
          <li>
            <img src={java_icon} alt="java"></img>
          </li>
          <li>
            <img src={react_icon} alt="react"></img>
          </li>
        </ul>
      </section>
      <section id="projects" className="projects">
        <h2>Projects</h2>
        <ul>
          <li>
            <ProjectCard
              name="Maze Generation"
              desc="Exploration of different perfect-maze generation algorithms."
              img={maze_gen}
              demo="/projects/MazeGeneration"
              source="https://github.com/WeslyOrtega/MazeGenerationReact"
            />
          </li>
          <li>
            <ProjectCard
              name="Fear of Dark"
              desc="2D platformer made with Unity"
              img={fear_of_dark}
              demo="/projects/FearOfDark"
              source="https://github.com/WeslyOrtega/FearOfDark"
            />
          </li>
          <li>
            <ProjectCard
              name="Badger Volunteering"
              desc="Matching system to pair users to their ideal volunteer role"
              img={badger}
              demo="https://badgervolunteering.herokuapp.com/"
              source="https://github.com/WeslyOrtega/BadgerVolunteering"
            />
          </li>
          <li>
            <ProjectCard
              name="Crypto Trackr"
              desc="Cryptocurrency price tracker"
              img={crypto}
              demo="https://jinychoi.dev/crypto/"
              source="https://github.com/Hadiasemi/Crypto-Tracker"
            />
          </li>
        </ul>
      </section>
      <section id="contact" className="contact">
        <h2>Contact Me</h2>
        <ul>
          <li>
            <a href="https://github.com/WeslyOrtega">
              <BsGithub className="icon" size="1.5rem" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/wesly_ortega/">
              <BsInstagram className="icon" size="1.5rem" />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/wesly-ortega/">
              <BsLinkedin className="icon" size="1.5rem" />
            </a>
          </li>
          <li>
            <a href="mailto:weslyortega@gmail.com">
              <BsEnvelopeFill className="icon" size="1.5rem" />
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
}
