import "./App.css";

import Navbar from "./Global/Components/Navbar/Navbar";
import Home from "./Home/Home";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Page404 from "./Global/Pages/404/404";
import FearOfDark from "./FearOfDark/FearOfDark";
import MazeGeneration from "./MazeGeneration/MazeGeneration";

function App() {
  return (
    <div className="Container">
      <Navbar />
      <div className="main">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects/FearOfDark" element={<FearOfDark />} />
            <Route
              path="/projects/MazeGeneration"
              element={<MazeGeneration />}
            />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
