import "./Navbar.css";

import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

export default function Navbar() {
  return (
    <div className="nav">
      <a href="/" className="webName">
        Wesly Ortega
      </a>
      <MobileNav />
      <DesktopNav />
    </div>
  );
}
