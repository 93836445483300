export default function NavItems() {
  return (
    <ul>
      <li>
        <a href="/#about">About</a>
      </li>
      <li>
        <a href="/#technologies">Technologies</a>
      </li>
      <li>
        <a href="/#projects">Projects</a>
      </li>
      <li>
        <a href="/#contact">Contact</a>
      </li>
    </ul>
  );
}
