import "./TemplateData/style.css";

import { Unity, useUnityContext } from "react-unity-webgl";

export default function FearOfDark() {
  var buildUrl = "/Build";
  var loaderUrl = buildUrl + "/v0.6.loader.js";
  var config = {
    loaderUrl: loaderUrl,
    dataUrl: buildUrl + "/v0.6.data",
    frameworkUrl: buildUrl + "/v0.6.framework.js",
    codeUrl: buildUrl + "/v0.6.wasm",
    streamingAssetsUrl: "StreamingAssets",
    companyName: "Wesly Ortega",
    productName: "Fear Of Dark",
    productVersion: "0.6",
  };

  const { unityProvider, loadingProgression, isLoaded, requestFullscreen } =
    useUnityContext(config);

  return (
    <div className="page-container">
      <div id="unity-container" className="unity-desktop">
        <div className="screen-container">
          <div className="player-container">
            <Unity
              className="unity-player"
              unityProvider={unityProvider}
              style={{
                visibility: isLoaded ? "visible" : "hidden",
              }}
            />
          </div>
          <div
            id="unity-loading-bar"
            style={{ display: isLoaded ? "none" : "unset" }}
          >
            <div id="unity-logo"></div>
            <div id="unity-progress-bar-empty">
              <div
                id="unity-progress-bar-full"
                style={{ minWidth: `${loadingProgression * 100}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div id="unity-warning"></div>
        <div id="unity-footer">
          <div id="unity-webgl-logo"></div>
          <div
            id="unity-fullscreen-button"
            onClick={() => requestFullscreen(true)}
          ></div>
          <div id="unity-build-title">Fear Of Dark</div>
        </div>
      </div>
    </div>
  );
}
